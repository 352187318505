import request from './config/request';
import i18n from '@/i18n';

// 取得遊戲球種
export function apiGetGameCat() {
  return request({
    url: `/GameInfo/GameOnlyCatID/${i18n.locale}`,
    method: 'get',
  });
}

// 取得遊戲類別
export function apiGetGameType() {
  return request({
    url: '/GameInfo/MenuGameType',
    method: 'post',
    auth: true,
  });
}

// 取得遊戲菜單
export function apiGetGameMenu() {
  return request({
    url: '/GameInfo/Menu',
    method: 'post',
    auth: true,
  });
}

// 取得遊戲聯盟
export function apiGetGameLeague(data) {
  return request({
    url: '/GameInfo/GameResultLeagues',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲賽果
export function apiGetGameResult(data) {
  return request({
    url: '/GameInfo/GameResult',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲限紅
export function apiGetGameLimit() {
  return request({
    url: '/GameInfo/MainBetInfo',
    method: 'post',
    auth: true,
  });
}

// 取得遊戲資訊
export function apiGetGameInfo(GameIDs) {
  return request({
    url: '/GameInfo/BetInfo',
    method: 'post',
    auth: true,
    data: { GameIDs: JSON.stringify(GameIDs) },
  });
}

// 取得盤口
export function apiGetDetail(data) {
  return request({
    url: '/GameInfo/GameDetail',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得search 盤口
export function apiGetSearch(keyword) {
  const data = { KeyWord: keyword };
  return request({
    url: '/GameInfo/SearchGameDetail',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得盤口變動
export function apiGetDetailSmall(data) {
  return request({
    url: '/GameInfo/GamelistSmall',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得盤口限額
// - 待移除
export function apiGetDetailLimit(data) {
  return request({
    url: '/GameInfo/MainBetInfo',
    method: 'post',
    auth: true,
    data,
  });
}

// 投注
export function apiPostPlay(list) {
  return request({
    url: '/GameInfo/Play',
    method: 'post',
    auth: true,
    data: { list },
  });
}

// 取得投注結果
export function apiGetPlayResult(traceCodeKey) {
  return request({
    url: '/GameInfo/playState',
    method: 'post',
    auth: true,
    data: { traceCodeKey },
  });
}

// 取得注單
export function apiGetTicket(data) {
  return request({
    url: '/GameInfo/Ticket/betHistory',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得注單統計
export function apiGetTicketStats(data) {
  return request({
    url: '/GameInfo/Ticket/betDayHistory',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得限紅菜單
export function apiGetLimitMenu() {
  return request({
    url: '/GameInfo/GetLimitGameMenu',
    method: 'post',
    auth: true,
  });
}

// 取得限紅
export function apiGetLimit(CatID = 1) {
  return request({
    url: '/GameInfo/GetLimitGameTable',
    method: 'post',
    auth: true,
    data: { CatID },
  });
}

// 取得設定
export function apiGetSetting(time) {
  return request({
    url: '/GameInfo/FrontEvn/Config',
    method: 'post',
    auth: true,
    hideError: true,
    timeout: 2000,
  });
}

// 更新設定
export function apiPostSetting(setting) {
  return request({
    url: '/GameInfo/FrontEvn/save',
    method: 'post',
    auth: true,
    data: { SetJson: JSON.stringify(setting) },
  });
}

// 取得公告
export function apiGetAnnouncement(data) {
  return request({
    url: '/GameInfo/Ann',
    method: 'post',
    auth: true,
    data,
  });
}

// 新增 log
export function apiPostLog(data) {
  return request({
    url: `/GameInfo/GameLog`,
    method: 'post',
    auth: true,
    data,
  });
}

// 取得新聞
export function apiGetNews({ pageIndex = 1, pageSize = 10 } = {}) {
  const data = { Pagesize: pageSize, Currentpage: pageIndex };
  return request({
    url: '/GameInfo/SportNews/List',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得新聞內容
export function apiGetNewsContent(newsId) {
  const data = { ArticleId: newsId };
  return request({
    url: '/GameInfo/SportNews/Detail',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得影片(十大好球)
export function apiGetVideo() {
  return request({
    url: '/GameInfo/SportNews/Video',
    method: 'post',
    auth: true,
  });
}

// 取得直播
export function apiGetLive() {
  return request({
    url: '/GameInfo/Live/XCFWD_3',
    method: 'post',
    auth: true,
  });
}

// 取得影城
export function apiGetMovie() {
  return request({
    url: '/GameInfo/Live/XCFWDMOVE_',
    method: 'post',
    auth: true,
  });
}

export function apiGetOddsHistory(data) {
  return request({
    url: '/GameInfo/GameInfoLog',
    method: 'post',
    auth: true,
    data,
  });
}

export function apiGetSuper8() {
  return request({
    url: '/SuperEight/GetAutoBetInfos',
    method: 'post',
    auth: true,
  });
}

export function apiPostSuper8(data) {
  return request({
    url: '/SuperEight/Bet',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得投注結果
export function apiGetSuper8Result(traceCodeKey) {
  return request({
    url: '/GameInfo/playState',
    method: 'post',
    auth: true,
    hideError: true,
    data: { traceCodeKey },
  });
}

export function apiGetChatTexts(API_BASE_URL) {
  return request({
    baseURL: API_BASE_URL,
    url: '/outputApi/DefaultMes',
    method: 'get',
  });
}

export function apiGetChatEmojis(API_BASE_URL) {
  return request({
    baseURL: API_BASE_URL,
    url: '/outputApi/EmojiInit',
    method: 'get',
  });
}

export function apiGetTicket97(data) {
  return request({
    url: '/GameInfo/Ticket/ParlaymutDetail',
    method: 'post',
    auth: true,
    data,
  });
}

// 賣單
export function apiPostSell(data) {
  return request({
    url: '/GameInfo/Ticket/sell',
    method: 'post',
    auth: true,
    data,
  });
}

export function apiGetLiveEvents() {
  return request({
    url: '/GameInfo/LiveGame',
    auth: true,
    method: 'post',
    data: {},
  });
}

export function apiGetLiveEvents2() {
  return request({
    url: 'GameInfo/LiveLinkGame',
    auth: true,
    method: 'post',
    data: {},
  });
}
