import { apiGetTicketStats, apiGetTicket } from '@/api/game';

export default {
  namespaced: true,
  state: {
    sellings: new Map(),
  },
  getters: {},
  mutations: {},
  actions: {
    updateTicketStats(store, postData) {
      return apiGetTicketStats(postData);
    },
    updateTickets(store, postData) {
      return apiGetTicket(postData);
    },
  },
};
