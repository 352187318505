import Vue from 'vue';
import Vuex from 'vuex';
import { loginRoute, router } from '@/router';
import { i18nGetLocale, i18nSetLocale } from '@/i18n';
import {
  apiGetSite,
  apiGetUser,
  apiGetBalance,
  apiPutPassword,
  apiPutName,
  apiPostLogin,
  apiPostLogout,
} from '@/api';
import {
  sessionGetToken,
  sessionSetToken,
  sessionClearToken,
  sessionGetAccount,
  sessionSetAccount,
  sessionClearAccount,
} from '@/config/session';
import { LOGIN, LOGOUT } from '@/config/storage';
import Game from './modules/Game';
import MoreGame from './modules/MoreGame';
import BetCart from './modules/BetCart';
import Setting from './modules/Setting';
import History from './modules/History';
import Chat from './modules/Chat';
import Lives from './modules/Lives';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { Game, BetCart, MoreGame, Setting, History, Chat, Lives },
  state: {
    appLoading: false,
    siteInfo: null,
    siteLabel: '',
    userInfo: null,
    userBalance: '',
    userTickets: 0,
    userUnread: 0,
    userFlags: null,
    i18nLocale: i18nGetLocale(),
    sessionToken: sessionGetToken(),
    sessionAccount: sessionGetAccount(),
    darkTheme: false,
  },
  getters: {
    siteOk(state) {
      return state.siteInfo !== null;
    },
    siteMaintaining(state) {
      const value = state.siteInfo?.MainTain?.Status;
      if (typeof value === 'undefined') return false;
      return value !== 1;
    },
    siteMaintainingMessage(state) {
      const value = state.siteInfo?.MainTain?.Message;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteLoginLogo(state) {
      const value = state.siteInfo?.LoginLogoPath;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteMainLogo(state) {
      const value = state.siteInfo?.MainLogoPath;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteApiVersion(state) {
      const value = state.siteInfo?.Ver;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteLiveOpen(state) {
      const { siteInfo } = state;
      if (siteInfo === null) return false;
      const { ShowLiveFlag } = siteInfo;
      return !!ShowLiveFlag;
    },
    siteMovieOpen(state) {
      const { siteInfo } = state;
      if (siteInfo === null) return false;
      const { ShowMovieFlag } = siteInfo;
      return !!ShowMovieFlag;
    },
    userOk(state) {
      return state.userInfo !== null;
    },
    userAccount(state) {
      const value = state.userInfo?.mbID;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userNewAccount(state) {
      const value = state.userInfo?.mbID;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userPasswordExpired(state) {
      const value = state.userInfo?.pwExpire;
      if (typeof value === 'undefined') return false;
      return value;
    },
    userPasswordExpiredMessage(state) {
      const value = state.userInfo?.pwMessage;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userName(state) {
      const value = state.userInfo?.RealName;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userWinMax(state) {
      const win = state.siteInfo?.MaxWin;
      const currency = state.userInfo?.Currency;
      if (typeof win === 'undefined' || typeof currency === 'undefined' || !(currency in win)) {
        return 0;
      }
      return win[currency];
    },
    userOddsAdjustment(state) {
      const { userInfo, userFlags } = state;
      const key = 'AdjOdds';
      // eslint-disable-next-line no-prototype-builtins
      if (userInfo?.hasOwnProperty(key)) {
        return userInfo[key];
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (userFlags?.hasOwnProperty(key)) {
        return userFlags[key];
      }
      return 0;
    },
    userPromotionOpen(state) {
      const { userInfo, userFlags } = state;
      const key = 'UseMesFlag';

      // eslint-disable-next-line no-prototype-builtins
      if (userInfo?.hasOwnProperty(key)) {
        return userInfo[key];
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (userFlags?.hasOwnProperty(key)) {
        return userFlags[key];
      }
      return false;
    },
    userSuper8Open(state) {
      const { userInfo, userFlags } = state;
      const key = 'S8OpenFlag';

      // eslint-disable-next-line no-prototype-builtins
      if (userInfo?.hasOwnProperty(key)) {
        return userInfo[key];
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (userFlags?.hasOwnProperty(key)) {
        return userFlags[key];
      }
      return false;
    },
    userDGLiveOpen(state) {
      const { userInfo, userFlags } = state;
      const key = 'LiveGameOpenFlag';

      // eslint-disable-next-line no-prototype-builtins
      if (userInfo?.hasOwnProperty(key)) {
        return userInfo[key];
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (userFlags?.hasOwnProperty(key)) {
        return userFlags[key];
      }
      return false;
    },
    userSPLotteryOpen(state) {
      const { userInfo, userFlags } = state;
      const key = 'LotteryGameOpenFlag';

      // eslint-disable-next-line no-prototype-builtins
      if (userInfo?.hasOwnProperty(key)) {
        return userInfo[key];
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (userFlags?.hasOwnProperty(key)) {
        return userFlags[key];
      }
      return false;
    },
    sessionOk(state) {
      return state.sessionToken && state.sessionAccount;
    },
    // limit2: 槍數限紅
    isLimit2Show(state) {
      return !state.userInfo?.DirectMem;
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.appLoading = loading;
    },
    showLoading(state) {
      state.appLoading = true;
    },
    hideLoading(state) {
      state.appLoading = false;
    },
    setSiteInfo(state, info) {
      const { SiteSkin = '', SiteTitle } = info;
      state.siteInfo = info;
      if (SiteSkin) {
        state.siteLabel = SiteSkin.toLowerCase();
      }
      if (SiteTitle) {
        document.title = SiteTitle;
      }
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setUserBalance(state, balance) {
      state.userBalance = balance;
    },
    setUserTickets(state, tickets) {
      state.userTickets = tickets;
    },
    setUserUnread(state, unread) {
      state.userUnread = unread;
    },
    setUserFlags(state, flags) {
      state.userFlags = flags;
    },
    setUserName(state, name) {
      if (state.userInfo !== null) {
        state.userInfo.RealName = name;
      }
    },
    clearUser(state) {
      state.userInfo = null;
      state.userBalance = '';
      state.userTickets = 0;
      state.userUnread = 0;
      state.userFlags = {};
    },
    setI18nLocale(state, locale) {
      const newLocale = i18nSetLocale(locale);
      if (state.i18nLocale !== newLocale) {
        state.i18nLocale = newLocale;
      }
    },
    setSessionToken(state, token) {
      sessionSetToken(token);
      state.sessionToken = token;
    },
    setSessionAccount(state, account) {
      sessionSetAccount(account);
      state.sessionAccount = account;
    },
    clearSession(state) {
      sessionClearToken();
      sessionClearAccount();
      state.sessionToken = '';
      state.sessionAccount = '';
    },
    setDarkTheme(state, dark) {
      if (dark) {
        state.darkTheme = true;
      } else {
        state.darkTheme = false;
      }
    },
    toggleTheme(state) {
      const { darkTheme } = state;
      state.darkTheme = !darkTheme;
      localStorage.setItem('Theme', darkTheme ? '' : 'dark');
    },
  },
  actions: {
    initTheme({ state }) {
      const value = localStorage.getItem('Theme');
      if (value === 'dark') {
        state.darkTheme = true;
      } else {
        state.darkTheme = false;
      }
    },
    initSite({ commit, getters }) {
      return apiGetSite().then((response) => {
        const { data } = response;
        if (data) {
          commit('setSiteInfo', data);
          return getters.siteMaintaining;
        }
      });
    },
    updateUser({ commit }) {
      return apiGetUser().then((response) => {
        const { data } = response;
        if (data) {
          commit('setUserInfo', data);
        }
      });
    },
    // 除了更新用戶餘額外, 還會更新未結帳注單數, 未讀客服訊息數
    updateUserBalance({ commit }) {
      return apiGetBalance().then((response) => {
        const { data } = response;
        if (data) {
          const { Balance: balance = '', TicketCount: tickets = 0, MesCount: unread = 0 } = data;
          commit('setUserBalance', balance);
          commit('setUserTickets', tickets);
          commit('setUserUnread', unread);
        }
      });
    },
    putUserPassword(store, { newPassword, oldPassword }) {
      return apiPutPassword({ newPassword, oldPassword });
    },
    putUserName({ dispatch }, name) {
      return apiPutName(name).then(() => {
        return dispatch('updateUser');
      });
    },
    login({ commit, getters }, { account, password }) {
      return apiPostLogin({ account, password }).then((response) => {
        const { data } = response;
        const token = data?.loginID;
        const info = data?.mb;
        const account = info?.mbID;
        commit('setSessionToken', token);
        commit('setSessionAccount', account);
        commit('setUserInfo', info);
        if (data.gameStatus) {
          commit('setUserFlags', data.gameStatus);
        }
        localStorage.setItem(LOGIN, token);
        if (window.OddData?.clear) {
          window.OddData.clear();
        }
        return info; // getters.userPasswordExpired;
      });
    },
    logout({ state, commit }) {
      const { sessionToken } = state;
      if (!sessionToken) return;
      return apiPostLogout().then(() => {
        commit('clearSession');
        commit('MoreGame/hideStandaloneLive', null, { root: true });
        localStorage.setItem(LOGOUT, sessionToken);
        localStorage.removeItem('limits');
        localStorage.removeItem('limits_key');
        if (window.OddData?.clear) {
          window.OddData.clear();
        }
        if (router.currentRoute.name !== loginRoute.name) {
          router.replace(loginRoute);
        }
      });
    },
  },
});
