import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { I18N_LOCALE } from '@/config/storage';
import elementTWLocale from 'element-ui/lib/locale/lang/zh-TW';
import elementCNLocale from 'element-ui/lib/locale/lang/zh-CN';
import elementENLocale from 'element-ui/lib/locale/lang/en';
import zhTW_Locale from './locales/zh-TW';
import zhCN_Locale from './locales/zh-CN';
import en_Locale from './locales/en';
import vi_Locale from './locales/vi';

Vue.use(VueI18n);

// 'tw', 'cn', 'en', 'vi', 'th', 'jp'
const messages = {
  tw: {
    ...zhTW_Locale,
    ...elementTWLocale,
  },
  cn: {
    ...zhCN_Locale,
    ...elementCNLocale,
  },
  en: {
    ...en_Locale,
    ...elementENLocale,
  },
  vi: {
    ...vi_Locale,
    ...elementENLocale,
  },
};

const locales = Object.keys(messages);

let locale = localStorage.getItem(I18N_LOCALE);
if (!locale || !locales.includes(locale)) {
  locale = locales[0];
}

export const i18n = new VueI18n({
  messages,
  locale,
});

export default i18n;

export const i18nGetLocale = () => i18n.locale;

export const i18nSetLocale = (locale) => {
  if (locale && locale !== i18n.locale && locales.includes(locale)) {
    i18n.locale = locale;
    localStorage.setItem(I18N_LOCALE, locale);
  }
  return i18n.locale;
};
