// 客服
// Customer Service

import request from './config/request';

// 取得客服歡迎訊息
export function apiGetCServiceWelcome() {
  return request({
    url: '/GameInfo/QAMes/firstMes',
    method: 'post',
    auth: true,
  });
}

// 取得客服對話訊息
export function apiGetCServiceMessages() {
  return request({
    url: '/GameInfo/QAMes/LiveList',
    method: 'post',
    auth: true,
  });
}

// 取得客服未讀訊息數
export function apiGetCServiceUnread() {
  return request({
    url: '/GameInfo/QAMes/CountMes',
    method: 'post',
    auth: true,
  });
}

// 讀取客服訊息
export function apiCServicePostRead() {
  return request({
    url: '/GameInfo/QAMes/ReadMes',
    method: 'post',
    auth: true,
  });
}

// 發送客服訊息
export function apiPostCServiceMessage(data) {
  return request({
    url: '/GameInfo/QAMes/send',
    method: 'post',
    auth: true,
    data,
  });
}

// 發送客服檔案
export function apiPostCServiceFile(data) {
  return request({
    url: '/GameInfo/QAMes/sendFile',
    method: 'post',
    auth: true,
    data,
  });
}
