export const PanelModeEnum = Object.freeze({
  normal: 1,
  lock: 2,
  result: 3,
});

export function getBet97Sets(option, count) {
  switch (option) {
    case 2:
      switch (count) {
        case 2:
          return 1;
        case 3:
          return 3;
        case 4:
          return 6;
        case 5:
          return 10;
        case 6:
          return 15;
        case 7:
          return 21;
        case 8:
          return 28;
        case 9:
          return 36;
        case 10:
          return 45;
        default:
          return 0;
      }
    case 3:
      switch (count) {
        case 3:
          return 1;
        case 4:
          return 4;
        case 5:
          return 10;
        case 6:
          return 20;
        case 7:
          return 35;
        case 8:
          return 56;
        case 9:
          return 84;
        case 10:
          return 120;
        default:
          return 0;
      }
    case 4:
      switch (count) {
        case 4:
          return 1;
        case 5:
          return 5;
        case 6:
          return 15;
        case 7:
          return 35;
        case 8:
          return 70;
        case 9:
          return 126;
        case 10:
          return 210;
        default:
          return 0;
      }
    case 5:
      switch (count) {
        case 5:
          return 1;
        case 6:
          return 6;
        case 7:
          return 21;
        case 8:
          return 56;
        case 9:
          return 126;
        case 10:
          return 252;
        default:
          return 0;
      }
    case 6:
      switch (count) {
        case 6:
          return 1;
        case 7:
          return 7;
        case 8:
          return 28;
        case 9:
          return 84;
        case 10:
          return 210;
        default:
          return 0;
      }
    case 7:
      switch (count) {
        case 7:
          return 1;
        case 8:
          return 8;
        case 9:
          return 36;
        case 10:
          return 120;
        default:
          return 0;
      }
    case 8:
      switch (count) {
        case 8:
          return 1;
        case 9:
          return 9;
        case 10:
          return 45;
        default:
          return 0;
      }
    case 9:
      switch (count) {
        case 9:
          return 1;
        case 10:
          return 10;
        default:
          return 0;
      }
    case 10:
      switch (count) {
        case 10:
          return 1;
        default:
          return 0;
      }
  }
}
