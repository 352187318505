import axios from 'axios';
import router, { loginRoute } from '@/router';
import store from '@/store';
import * as message from '@/utils/messageHandler.js';
import { API_BASE_URL } from './url';
import { API_LOGOUT_ERRORS } from './error';

const instance = axios.create({ baseURL: API_BASE_URL });

instance.interceptors.request.use(
  (config) => {
    const { headers, auth } = config;
    const { sessionToken, sessionAccount, i18nLocale } = store.state;
    headers.SSSLANG = i18nLocale;
    if (auth) {
      headers.SSSToken = sessionToken;
      headers.SSSMBID = sessionAccount;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data } = response;
    const { code } = data;
    if (code < 0) {
      if (response.config?.hideError) {
        return Promise.reject(response);
      }
      if (API_LOGOUT_ERRORS.includes(code)) {
        if (router.currentRoute.name !== loginRoute.name) {
          store.commit('clearSession');
          store.commit('clearUser');
          return router.replace(loginRoute).then(() => {
            if (data?.message) {
              message.error(data.message);
            }
          });
        }
      } else if (data?.message) {
        message.error(data.message);
      }
      return Promise.reject(response);
    } else {
      return data;
    }
  },
  (error) => {
    console.error(error);
    store.commit('setLoading', false);
    return Promise.reject(error);
  }
);

export default instance;
